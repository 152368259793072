<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		
		<div class="security" v-loading="loading">
			<div class="item-wrap" v-if="type == 'all'">
				<div class="item">
					<div class="item-content">
						<i class="iconfont iconxiugaidenglumima"></i>
						<div class="name-wrap">
							<div class="name">{{ $lang('tmdDOTco.00822', $route) }}</div>
							<div class="content">{{ $lang('tmdDOTco.00823', $route) }}</div>
						</div>
					</div>
					<div class="btn"><el-button type="primary" size="medium" @click="edit('password')">{{ $lang('tmdDOTco.00824', $route) }}</el-button></div>
				</div>
				<!-- <div class="item">
					<div class="item-content">
						<i class="iconfont iconyouxiang1"></i>
						<div class="name-wrap">
							<div class="name">邮箱绑定</div>
							<div class="content">验证后，可用于快速找回登录密码及支付密码</div>
						</div>
					</div>
					<div class="btn"><el-button type="primary" size="medium" @click="edit('email')">修改</el-button></div>
				</div> -->
				<div class="item">
					<div class="item-content">
						<i class="iconfont iconshoujiyanzheng"></i>
						<div class="name-wrap">
							<div class="name">{{ $lang('tmdDOTco.00825', $route) }}</div>
							<div class="content">{{ $lang('tmdDOTco.00826', $route) }}</div>
						</div>
					</div>
					<div class="btn"><el-button type="primary" size="medium" @click="edit('tell')">{{ $lang('tmdDOTco.00827', $route) }}</el-button></div>
				</div>
			<!-- 	<div class="item">
					<div class="item-content">
						<i class="el-icon-lock"></i>
						<div class="name-wrap">
							<div class="name">支付密码</div>
							<div class="content">互联网支付存在被盗风险，建议您定期更改支付密码以保护账户安全</div>
						</div>
					</div>
					<div class="btn"><el-button type="primary" size="medium" @click="edit('payPassWord')">修改</el-button></div>
				</div> -->
			</div>
			<div class="edit" v-if="type == 'password'">
				<div class="title">{{ $lang('tmdDOTco.00828', $route) }}</div>
				<div v-if="memberInfo.password">
					<div class="pass-form">
						<el-form :model="passWordForm" :rules="passWordRules" ref="passWordRef" label-width="100px">
							<el-form-item :label="$lang('tmdDOTco.00829', $route)" prop="oldPass">
								<el-input type="password" :placeholder="$lang('tmdDOTco.00830', $route)" v-model="passWordForm.oldPass"></el-input>
							</el-form-item>
							<el-form-item :label="$lang('tmdDOTco.00831', $route)" prop="pass">
								<el-input type="password" :placeholder="$lang('tmdDOTco.00831', $route)" v-model="passWordForm.pass"></el-input>
							</el-form-item>
							<el-form-item :label="$lang('tmdDOTco.00833', $route)" prop="checkPass">
								<el-input type="password" :placeholder="$lang('tmdDOTco.00834', $route)" v-model="passWordForm.checkPass"></el-input>
							</el-form-item>
						</el-form>
					</div>
					<div class="btn">
						<el-button type="primary" @click="save">{{ $lang('tmdDOTco.00835', $route) }}</el-button>
						<el-button @click="type = 'all'">{{ $lang('tmdDOTco.00836', $route) }}</el-button>
					</div>
				</div>
				<div v-else class="tell-pass">
					<el-form :model="tellPassForm" :rules="tellPassRules" ref="tellPassRef" label-width="100px">
						<el-form-item :label="$lang('tmdDOTco.00837', $route)" prop="code">
							<el-input :placeholder="$lang('tmdDOTco.00838', $route)" maxlength="4" v-model="tellPassForm.code">
								<template slot="append">
									<img :src="captcha.img" mode class="captcha" @click="getcaptcha" />
								</template>
							</el-input>
						</el-form-item>
						<el-form-item :label="$lang('tmdDOTco.00839', $route)" prop="tellPassDynacode">
							<el-input :placeholder="$lang('tmdDOTco.00840', $route)" v-model="tellPassForm.tellPassDynacode">
								<template slot="append">
									<el-button type="primary" @click="getTellPassCode">{{ tellPassForm.tellPassCodeText }}</el-button>
								</template>
							</el-input>
						</el-form-item>
						<p class="tell-code">{{ $lang('tmdDOTco.00841', $route) + (memberInfo.mobile | mobile) }}</p>
						<el-form-item :label="$lang('tmdDOTco.00842', $route)" prop="pass">
							<el-input type="password" :placeholder="$lang('tmdDOTco.00842', $route)" v-model="tellPassForm.pass"></el-input>
						</el-form-item>
						<el-form-item :label="$lang('tmdDOTco.00844', $route)" prop="checkPass">
							<el-input type="password" :placeholder="$lang('tmdDOTco.00845', $route)" v-model="tellPassForm.checkPass"></el-input>
						</el-form-item>
					</el-form>
					<div class="btn">
						<el-button type="primary" @click="tellPassSave">{{ $lang('tmdDOTco.00846', $route) }}</el-button>
						<el-button @click="type = 'all'">{{ $lang('tmdDOTco.00847', $route) }}</el-button>
					</div>
				</div>
			</div>
			<div class="edit" v-if="type == 'email'">
				<div class="title">{{ $lang('tmdDOTco.00848', $route) }}</div>
				<div class="pass-form">
					<el-form :model="emailForm" :rules="emailRules" ref="emailRef" label-width="100px">
						<el-form-item :label="$lang('tmdDOTco.00849', $route)" prop="email" v-if="emailForm.currEmail">
							<p>{{ emailForm.currEmail }}</p>
						</el-form-item>
						<el-form-item :label="$lang('tmdDOTco.00850', $route)" prop="email">
							<el-input type="email" :placeholder="$lang('tmdDOTco.00851', $route)" v-model="emailForm.email"></el-input>
						</el-form-item>
						<el-form-item :label="$lang('tmdDOTco.00852', $route)" prop="code">
							<el-input :placeholder="$lang('tmdDOTco.00853', $route)" maxlength="4" v-model="emailForm.code">
								<template slot="append">
									<img :src="captcha.img" mode class="captcha" @click="getcaptcha" />
								</template>
							</el-input>
						</el-form-item>
						<el-form-item :label="$lang('tmdDOTco.00854', $route)" prop="emailDynacode">
							<el-input :placeholder="$lang('tmdDOTco.00855', $route)" v-model="emailForm.emailDynacode">
								<template slot="append">
									<el-button type="primary" @click="getEmailCode">{{ emailForm.emailCodeText }}</el-button>
								</template>
							</el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="btn">
					<el-button type="primary" @click="bindEmail">{{ $lang('tmdDOTco.00856', $route) }}</el-button>
					<el-button @click="type = 'all'">{{ $lang('tmdDOTco.00857', $route) }}</el-button>
				</div>
			</div>
			<div class="edit" v-if="type == 'tell'">
				<div class="title">{{ $lang('tmdDOTco.00858', $route) }}</div>
				<div class="pass-form">
					<el-form :model="tellForm" :rules="tellRules" ref="tellRef" label-width="100px">
						<el-form-item :label="$lang('tmdDOTco.00859', $route)" prop="email" v-if="tellForm.currTell">
							<p>{{ tellForm.currTell }}</p>
						</el-form-item>
						<el-form-item :label="$lang('tmdDOTco.00860', $route)" prop="tell">
							<el-input type="tell" :placeholder="$lang('tmdDOTco.00861', $route)" v-model="tellForm.tell"></el-input>
						</el-form-item>
						<el-form-item :label="$lang('tmdDOTco.00862', $route)" prop="code">
							<el-input :placeholder="$lang('tmdDOTco.00863', $route)" maxlength="4" v-model="tellForm.code">
								<template slot="append">
									<img :src="captcha.img" mode class="captcha" @click="getcaptcha" />
								</template>
							</el-input>
						</el-form-item>
						<el-form-item :label="$lang('tmdDOTco.00864', $route)" prop="tellDynacode">
							<el-input :placeholder="$lang('tmdDOTco.00865', $route)" v-model="tellForm.tellDynacode">
								<template slot="append">
									<el-button type="primary" @click="gettellCode">{{ tellForm.tellCodeText }}</el-button>
								</template>
							</el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="btn">
					<el-button type="primary" @click="bindtell">{{ $lang('tmdDOTco.00866', $route) }}</el-button>
					<el-button @click="type = 'all'">{{ $lang('tmdDOTco.00867', $route) }}</el-button>
				</div>
			</div>
			<div class="edit-pay" v-if="type == 'payPassWord'">
				<div class="title">{{ $lang('tmdDOTco.00868', $route) }}</div>
				<div class="container">
					<div class="name" v-if="step != 0">{{ $lang('tmdDOTco.00869', $route) }}</div>
					<div class="name" v-else-if="isSend">{{ $lang('tmdDOTco.00870', $route) + (tellForm.currTell | mobile) }}</div>
					<div class="password-wrap">
						<el-input :maxlength="step == 0 ? 4 : 6" @change="input" ref="input" :auto-focus="true" v-model="payInput" :placeholder="palceText" v-if="step == 0"></el-input>
						<el-input
							:maxlength="step == 0 ? 4 : 6"
							@change="input"
							ref="input"
							:auto-focus="true"
							v-model="payInput"
							type="password"
							:placeholder="palceText"
							v-else
						></el-input>
						<div v-show="step == 0" class="dynacode" @click="sendMobileCode">{{ payCodeText }}</div>
					</div>
				</div>
				<div class="btn">
					<el-button type="primary" @click="bindPayPwd" :disabled="isClick">{{ $lang('tmdDOTco.00871', $route) }}</el-button>
					<el-button @click="type = 'all'">{{ $lang('tmdDOTco.00872', $route) }}</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import security from './security';
export default {
	name: 'security',
	mixins: [security]
};
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}	

.security {
	background: #ffffff;
	.el-form {
		margin: 0 30px;
		.captcha {
			vertical-align: top;
			max-width: inherit;
			width: 74px;
			max-height: 38px;
			line-height: 38px;
			cursor: pointer;
		}
	}
	.item {
		border-bottom: 1px solid #f1f1ff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px;
		.item-content {
			display: flex;
			align-items: center;
			i {
				font-size: 40px;
			}
			.name-wrap {
				margin-left: 20px;
			}
		}
	}
	.edit {
		padding: 20px;
		.title {
			padding-bottom: 5px;
			font-size: $ns-font-size-base;
			border-bottom: 1px solid #f1f1f1;
		}
		.pass-form {
			margin-top: 20px;
			display: flex;
			justify-content: center;
			.el-form {
				width: 500px;
			}
		}
		.btn {
			display: flex;
			justify-content: center;
		}
	}
	.tell-pass {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.el-form {
			width: 500px;
		}
		.tell-code {
			margin-left: 66px;
			margin-bottom: 20px;
		}
	}
	.edit-pay {
		padding: 20px;
		text-align: center;
		.title {
			font-size: $ns-font-size-base;
			border-bottom: 1px solid #f1f1f1;
			text-align: left;
			padding-bottom: 5px;
		}
		.dynacode {
			cursor: pointer;
			text-align: right;
			margin-right: 329px;
			color: $base-color;
			margin-bottom: 20px;
		}
		.el-input {
			width: 300px;
			margin-top: 20px;
		}
		.btn {
			margin-top: 20px;
		}
		.name {
			margin-top: 10px;
		}
	}
}
</style>
